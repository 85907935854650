.cards {
  display: flex;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }

  &__item {
    flex: 0 0 calc(33.333% - 8px);

    &:not(:first-child) {
      margin: 0 0 0 12px;
    }

    @media (max-width: 992px) {
      flex: 0 0 100%;

      &:not(:first-child) {
        margin: 5px 0 0;
      }
    }
  }
}
