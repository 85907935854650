.contacts {
  padding: 90px 0;
  background: #ddd5cc;

  @media (max-width: 767px) {
    padding: 40px 0 50px;
  }

  &Body {
    display: flex;
    padding: 0;

    @media (max-width: 767px) {
      padding: 0;
    }

    &__success {
      margin: 30px 0 0;
      font-size: 28px;
      line-height: 110%;
      text-align: center;
      letter-spacing: -0.02em;
      color: #ff5c00;
      @media (max-width: 992px) {
        font-size: 14px;
        line-height: 120%;
      }
    }

    &__content {
    }

    &__title {
      color: #272726;
      text-align: center;
    }

    &__subtitle {
      margin: 20px 0 0;
      text-align: center;
    }

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      margin: 60px 0 0;

      @media (max-width: 767px) {
        margin: 40px 0 0;
        flex-direction: unset;
        align-items: unsafe;
      }

      input {
        &.hidden {
          display: none;
        }
      }

      &Row {
        width: 100%;
        display: flex;
        justify-content: center;

        &:not(:first-child) {
          margin: 20px 0 0;
        }

        label {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            white-space: nowrap;
            font-size: 40px;
            line-height: 110%;
            letter-spacing: -0.02em;
            color: #000000;
          }

          input {
            flex: 0 1 435px;
            margin: 0 0 0 20px;

            &.err {
              border-bottom: 1px solid salmon;
            }
          }
        }
      }

      &Input {
        flex: 0 0 250px;
        margin: 30px 0 0 50px;

        &:nth-child(odd) {
          margin: 30px 0 0;
        }

        &:nth-child(1) {
          margin: 0;
        }

        &:nth-child(2) {
          margin: 0 0 0 50px;
        }

        &.err {
          border-bottom: 1px solid salmon;
        }

        @media (max-width: 767px) {
          flex: 0 0 calc(50% - 8px);
          margin: 15px 0 0 16px;

          &:nth-child(odd) {
            margin: 15px 0 0;
          }

          &:nth-child(1) {
            margin: 0;
          }

          &:nth-child(2) {
            margin: 0 0 0 16px;
          }
        }
      }

      &Btn {
        margin: 50px 0 0;

        @media (max-width: 767px) {
          margin: 20px auto 0;
        }
      }
    }
  }
}
