.form {
  @media (max-width: 767px) {
    max-width: 400px;
  }

  &__success {
    margin: 30px 0 0;
    font-size: 28px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ff5c00;
    @media (max-width: 992px) {
      font-size: 14px;
      line-height: 120%;
    }
  }

  &__row {
    display: flex;

    &:not(:first-child) {
      margin: 30px 0 0;

      @media (max-width: 1120px) {
        margin: 10px 0 0;
      }
      @media (max-width: 767px) {
        margin: 4px 0 0;
      }
    }

    &:nth-child(1) {
      padding: 0 76px;
      label {
        &:nth-child(2) {
          margin: 0 0 0 50px;
        }
      }
    }
    &:nth-child(2) {
      padding: 0 118px 0 148px;

      label {
        &:nth-child(2) {
          margin: 0 0 0 30px;
        }
      }
    }
    &:nth-child(3) {
      label {
        &:nth-child(1) {
          flex: 0 1 716px;
          @media (max-width: 1300px) {
            flex: 0 1 586px;
          }
          @media (max-width: 991px) {
            flex: 0 0 auto;
          }
        }
        &:nth-child(2) {
          margin: 0 0 0 40px;
        }
      }
    }
    &:nth-child(4) {
      padding: 0 0 0 80px;

      & > label {
        & > label {
          width: 234px;
        }

        &:nth-child(2) {
          margin: 0 0 0 50px;
        }
      }
    }

    @media (max-width: 1260px) {
      padding: 0 !important;

      & > label {
        &:nth-child(2) {
          margin: 0 0 0 20px !important;
        }
      }
    }

    @media (max-width: 992px) {
      &:nth-child(2) {
        padding: 0 30px 0 10px !important;
      }
      &:nth-child(3) {
        flex-direction: column-reverse;
        align-items: center;

        & > label {
          &:nth-child(1) {
            align-self: flex-start;
            margin: 10px 0 0;
          }
          &:nth-child(2) {
            margin: 0 !important;
          }
        }
      }
      &:nth-child(4) {
        flex-direction: column;
        align-items: center;

        & > label {
          &:nth-child(1) {
            align-self: flex-end;
            margin: 0 !important;
          }
          &:nth-child(2) {
            align-self: flex-start;
          }
        }
      }
    }

    @media (max-width: 767px) {
      &:nth-child(1) {
        flex-wrap: wrap;

        & > label {
          input {
            width: 120px;
          }
          &:nth-child(1) {
            margin: 0 auto 0 0;
          }
          &:nth-child(2) {
            width: 100%;
            justify-content: flex-end;
            justify-self: flex-end;
            margin: 4px 0 0 auto !important;
          }
        }
      }
      &:nth-child(2) {
        padding: 0 !important;

        & > label {
          &:nth-child(2) {
            margin: 0 0 0 5px !important;
          }
        }
      }
      &:nth-child(3) {
        align-items: flex-start;
        & > label {
          &:nth-child(2) {
            margin: 0 0 0 10px !important;
          }
          &:nth-child(1) {
            align-self: flex-end;

            input {
              width: 120px;
            }
          }
        }
      }
      &:nth-child(4) {
        align-items: flex-start;

        & > label {
          &:nth-child(1) {
            width: 100%;
          }
          &:nth-child(2) {
            align-self: center;
            margin: 4px 0 0 !important;
          }

          & > label {
            width: 120px;
          }
        }
      }
    }

    label {
      display: flex;
      flex: 1 1 auto;

      & > label {
        align-self: center;
      }

      & > span {
        white-space: nowrap;
        font-size: 40px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #ddd5cc;

        @media (max-width: 1120px) {
          font-size: 26px;
        }
        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 140%;
        }
      }

      input {
        align-self: center;
        margin: 0 0 0 20px;
        border-bottom: 1px solid rgba(221, 213, 204, 0.3);
        color: #ddd5cc;

        &.err {
          border-bottom: 1px solid salmon;
        }

        &::placeholder {
          color: rgba(221, 213, 204, 0.3);
        }

        &:hover {
          border-bottom: 1px solid #ddd5cc;
        }

        &:focus {
          color: #ddd5cc;
          border-bottom: 1px solid #ddd5cc;
        }

        @media (max-width: 1120px) {
          margin: 0 0 0 5px;
        }
      }
    }
  }

  &__btn {
    margin: 90px auto 0;

    @media (max-width: 992px) {
      margin: 40px auto 0;
    }
  }
}
