.description {
  font-size: 28px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #ff5c00;

  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 120%;
  }
}
