.card {
  background: #272726;
  border: 0.5px solid rgba(221, 213, 204, 0.07);
  border-radius: 23px;

  padding: 40px 25px;

  transition: all 0.3s ease;

  &:hover {
    border: 0.5px solid rgba(221, 213, 204, 0.3);
  }

  &:focus {
    border: 0.5px solid rgba(221, 213, 204, 0.3);
  }

  @media (max-width: 992px) {
    border-radius: 15px;
    padding: 15px;
  }

  &__dots {
    display: flex;
    padding: 0 0 7px 10px;

    @media (max-width: 992px) {
      padding: 0 0 5px 7px;
    }

    span {
      flex: 0 0 auto;
      width: 7px;
      height: 7px;
      border-radius: 50%;

      background: #ff5c00;

      &:not(:first-child) {
        margin: 0 0 0 5px;
      }

      @media (max-width: 992px) {
        width: 5px;
        height: 5px;

        &:not(:first-child) {
          margin: 0 0 0 3px;
        }
      }
    }
  }

  &__ico {
    margin: 20px 0 0;
    width: 50px;
    height: 50px;

    @media (max-width: 992px) {
      margin: 10px 0 0;
      width: 25px;
      height: 25px;
    }

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    margin: 40px 0 0;

    font-family: 'Neue Montreal';
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    color: #ddd5cc;

    @media (max-width: 992px) {
      margin: 10px 0 0;

      font-size: 12px;
      line-height: 15px;
      font-weight: 300;
    }
  }
}
