.title {
  font-size: 105px;
  line-height: 140%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #ddd5cc;

  @media (max-width: 1380px) {
    font-size: 80px;
  }
  @media (max-width: 1100px) {
    font-size: 70px;
  }
  @media (max-width: 992px) {
    font-size: 50px;
  }
  @media (max-width: 767px) {
    font-size: 28px;
  }
}
