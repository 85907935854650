.input {
  width: 100%;
  display: block;
  font-family: 'Neue Montreal';
  font-size: 17px;
  line-height: 140%;
  text-transform: uppercase;
  color: #272726;
  border-radius: 0;

  border-bottom: 1px solid #2727264d;

  background: transparent;

  transition: all 0.3s ease;

  &:hover {
    border-bottom: 1px solid #272726;
  }

  &:focus {
    border-bottom: 1px solid #272726;
  }

  &::placeholder {
    color: rgba(39, 39, 38, 0.3);
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 15px;
  }
}
