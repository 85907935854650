.services {
  padding: 100px 0;

  @media (max-width: 992px) {
    padding: 40px 0;
  }

  &__title {
    text-align: center;
  }

  &__description {
    margin: 80px 0 0;
    text-align: center;

    @media (max-width: 992px) {
      margin: 15px 0 0;
    }
  }
}
