.noise {
  pointer-events: none;
  user-select: none;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
