.about {
  padding: 100px 0;

  @media (max-width: 992px) {
    padding: 50px 0;
  }
  @media (max-width: 767px) {
    padding: 40px 0;
  }

  &__title {
    text-align: center;
  }

  &Body {
    &__btns {
      overflow: visible !important;
      max-width: 834px;
      margin: 80px auto 0;
      border-bottom: 1px solid rgba(221, 213, 204, 0.3);

      @media (max-width: 991px) {
        max-width: 262px;
        margin: 15px auto 0;
        padding: 0 0 2px !important;
      }

      &Item {
        cursor: pointer;
        width: 150px !important;
        font-size: 40px;
        line-height: 140%;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #ddd5cc;

        position: relative;

        @media (max-width: 991px) {
          font-size: 14px;
          line-height: 140%;
          width: 52px !important;
        }

        &::after {
          content: '';
          position: absolute;
          display: block;
          height: 1px;
          width: 100%;
          background-color: #ff5c00;
          bottom: -1px;

          opacity: 0;
          transition: all 0.3s ease;

          @media (max-width: 991px) {
            bottom: -3px;
          }
        }

        &.active {
          &::after {
            opacity: 1;
          }
        }

        &:nth-child(1) {
          width: 294px !important;

          @media (max-width: 991px) {
            width: 103px !important;
          }
        }
        &:nth-child(2) {
          width: 190px !important;

          @media (max-width: 991px) {
            width: 67px !important;
          }
        }
      }
    }

    &__content {
      margin: 80px auto 0;
      max-width: 1240px;

      @media (max-width: 991px) {
        margin: 25px auto 0;
      }

      &Item {
        opacity: 0 !important;

        &.active {
          opacity: 1 !important;
        }

        @media (max-width: 991px) {
          opacity: 1 !important;
        }
      }

      .text {
        p {
          font-size: 28px;
          line-height: 110%;
          text-align: center;
          letter-spacing: -0.02em;
          color: #ddd5cc;

          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 110%;
          }

          &:not(:first-child) {
            margin: 20px 0 0;

            @media (max-width: 767px) {
              margin: 10px 0 0;
            }
          }

          @media (max-width: 991px) {
            font-size: 16px;
            line-height: 110%;
          }
        }
      }

      .values {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        gap: 50px 108px;

        @media (max-width: 991px) {
          gap: 20px 15px;
        }

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;

          &Ico {
            @media (max-width: 767px) {
              width: 20px;
              height: 20px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          &Title {
            margin: 20px 0 0;

            font-size: 28px;
            line-height: 110%;
            text-align: center;
            letter-spacing: -0.02em;
            color: #ddd5cc;

            @media (max-width: 767px) {
              margin: 10px 0 0;

              font-size: 16px;
              line-height: 110%;
            }
          }
        }
      }
    }
  }
}
