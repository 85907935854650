.card {
  position: relative;
  padding: 0;
  transition: all 0.3s ease;

  &Top {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(221, 213, 204, 0.3);

    &:hover {
      .cardTop__title {
        color: #ff5c00;
      }
      .cardTop__arrow {
        svg {
          path {
            fill: #ff5c00;
          }
        }
      }
    }

    &__title {
      font-size: 48px;
      line-height: 110%;
      letter-spacing: -0.02em;
      color: #ddd5cc;
      padding: 15px 0 10px;

      transition: all 0.3s ease;

      @media (max-width: 991px) {
        font-size: 22px;
        line-height: 140%;
        padding: 3px 0;
      }
    }

    &__arrow {
      svg {
        transition: all 0.4s ease;
        transform: rotate(180deg);

        path {
          transition: all 0.3s ease;
        }

        @media (max-width: 991px) {
          width: 26px;
          height: 26px;
        }
      }
    }
  }

  &Content {
    display: flex;
    align-items: center;

    margin: 15px 0 0;
    opacity: 0;
    pointer-events: none;
    max-height: 0;
    transition: all 0.4s ease;
    overflow: hidden;

    @media (max-width: 991px) {
      margin: 4px 0 0;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &__img {
      flex: 0 0 auto;
      width: 680px;
      height: 316px;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }

      @media (max-width: 1200px) {
        width: 480px;
        height: 223px;
      }
      @media (max-width: 991px) {
        width: 360px;
        height: 167px;
      }
      @media (max-width: 767px) {
        width: 100%;
        height: auto;
      }
    }

    &__description {
      width: 560px;
      margin: 0 0 0 40px;

      font-family: 'Neue Montreal';
      font-size: 22px;
      line-height: 140%;
      color: #ddd5cc;

      @media (max-width: 767px) {
        width: 100%;
        margin: 4px 0 0;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

  &.active {
    border-bottom: 1px solid rgba(221, 213, 204, 0.3);
    .cardTop__arrow {
      svg {
        transform: rotate(0deg);
      }
    }
    .cardContent {
      opacity: 1;
      max-height: 400px;
      pointer-events: unset;
      padding: 0 0 15px;

      @media (max-width: 991px) {
        padding: 0 0 6px;
      }
    }
  }
}
