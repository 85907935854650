.cursor {
  position: fixed;
  background: #ff5c00;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  user-select: none;
  pointer-events: none;
  z-index: 1111;
  transform: scale(1) translate(-2px, -2px);
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.follower {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 64px;
  height: 64px;
  border: 1px solid #ddd5cc;
  border-radius: 100%;
  user-select: none;
  pointer-events: none;
  z-index: 1111;
  transform: scale(1);
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s ease background, 0.2s ease border-color;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &__draggable {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 120px;
    height: 120px;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 50%;

    transition: all 0.4s ease;
    opacity: 0;
    transform: scale(0);

    font-weight: 800;
    font-size: 17px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ddd5cc;

    svg {
      &:first-child {
        margin: 0 11px 0 0;
      }
      &:last-child {
        transform: scale(-1, 1);
        margin: 0 0 0 11px;
      }
    }
  }

  &.draggable {
    border: none;
    .follower__draggable {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@media (max-width: 767px) {
  .cursor {
    display: none;
  }

  .follower {
    display: none;
  }
}
