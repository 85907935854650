@font-face {
  font-family: 'NanumMyeongjo';
  font-display: swap;
  src: url('./assets/fonts/NanumMyeongjo.woff2') format('woff2'),
    url('./assets/fonts/NanumMyeongjo.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Neue Montreal';
  font-display: swap;
  src: url('./assets/fonts/NeueMontreal-Regular.woff2') format('woff2'),
    url('./assets/fonts/NeueMontreal-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Neue Montreal';
  font-display: swap;
  src: url('./assets/fonts/NeueMontreal-Medium.woff2') format('woff2'),
    url('./assets/fonts/NeueMontreal-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  font-display: swap;
  src: url('./assets/fonts/HelveticaNeueCyr-Light.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeueCyr-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  font-display: swap;
  src: url('./assets/fonts/HelveticaNeueCyr-Medium.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeueCyr-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  font-display: swap;
  src: url('./assets/fonts/HelveticaNeueCyr-Roman.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeueCyr-Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
