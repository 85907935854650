.main-ico {
  &.anim0 {
    #main-svg-dollar {
      animation: mainSvgDollarRotation 2s infinite linear;
    }
  }
  &.anim1 {
    #main-svg-handshake {
      animation: mainSvgHandShakeAnim 2s infinite linear;
    }
  }
  &.anim2 {
    #main-svg-arrows {
      animation: mainSvgClockAnim 2s infinite linear;
    }
  }
}

@keyframes mainSvgDollarRotation {
  0% {
    transform-origin: 50% 60%;
    transform: scale(1) rotate(0);
  }
  50% {
    transform: scale(0.9) rotate(180deg);
  }
  100% {
    transform-origin: 50% 60%;
    transform: scale(1) rotate(360deg);
  }
}

@keyframes mainSvgHandShakeAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes mainSvgClockAnim {
  0% {
    transform: rotate(0);
    transform-origin: center;
  }
  100% {
    transform-origin: center;
    transform: rotate(360deg);
  }
}
