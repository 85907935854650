.main {
  padding: 200px 0 100px;

  @media (max-width: 992px) {
    padding: 120px 0 50px;
  }
  @media (max-width: 767px) {
    padding: 90px 0 40px;
  }

  &Body {
    &__label {
      text-align: center;
      font-size: 40px;
      line-height: 140%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: rgba(221, 213, 204, 0.3);

      @media (max-width: 1380px) {
        font-size: 36px;
      }
      @media (max-width: 1100px) {
        font-size: 32px;
      }
      @media (max-width: 992px) {
        font-size: 26px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    &__title {
      margin: 10px 0 0;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 24px;
      }
    }

    &__cards {
      margin: 115px 0 0;

      @media (max-width: 1100px) {
        margin: 65px 0 0;
      }
      @media (max-width: 992px) {
        margin: 40px 0 0;
      }
    }
  }
}
